import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/src/theme/saasModern';
import React, { Fragment } from 'react';
import SEO from '../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../../containers/SaasModern/sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../containers/SaasModern/Navbar';
import Footer from '../../containers/SaasModern/Footer';
import DummyBanner from '../../containers/SaasModern/Banner/DummyBanner';

export default () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="B2B SaaS Leads"/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>
          <DummyBanner titleText={<>Subscription purchased successfully!<br/>You're in.</>}
                       subtitleText={
                         <>
                           We've sent you an email with further instructions. <strong>Happy
                           selling!</strong>
                           <br/>
                           In case of any issues, you can contact us at
                           hello@b2bsaasleads.com
                         </>
                       }
                       height={400}/>

          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
